<template>
    <ChartMetrics height="177px" :chartData="dataChart"></ChartMetrics>
</template>

<script>
import ChartMetrics from '@/components/local/metrics/ChartLine.vue'
import commonMixin from '@/mixins/common.js'
export default{
    components: {ChartMetrics},
    props: ['data','title', 'total'],
    mixins: [commonMixin ],
    computed:{
        dataChart(){
            let labels = []
            let datasets = {
                    label: '',
                    backgroundColor: '#E1ECFD',
                    borderColor: '#3382EA',
                    data: null,
                    tension: 0.05
                }                
            let data = []
            for(var indicador in this.data.timeSeries.datedValues){
                data.push( Number(this.data.timeSeries.datedValues[indicador].value) || 0 )

                labels.push(this.data.timeSeries.datedValues[indicador].date.day + '/' +
                this.data.timeSeries.datedValues[indicador].date.month + '/' +
                this.data.timeSeries.datedValues[indicador].date.year)
            }
            datasets.data = data
            if (this.typeNode === 'center') {
                return {labels,datasets:[datasets]}
            } else {
                return {labels,datasets:[datasets]}
            }
        }
    }
}
</script>