<script>
import Chart from 'chart.js'
import { generateChart, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

// CUSTOMIZAMOS LA GRAFICA DE LINEA PARA AÑADIR BARRAS DE COLORES A LOS LATERALES
Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.defaults.global.legend.labels.usePointStyle = true;
const CustomLine = generateChart('custom-line-gr', 'LineWithLine')
export default {
  name: "valid_name",
  extends: CustomLine,
  mixins: [reactiveProp],
  props:['chartData', 'legend'],
  data: () => ({
    chartDataCustom: null,
  }),
  created () {
    this.chartDataCustom = this.chartData
  },
  watch: {
    chartDataCustom () {
      // MOSTRAMOS VALORES DEL EJE Y A IZQUIERDA Y DERECHA
      this.renderChart(this.chartData, this.options)
    }
  },
  computed: {
    options () {
      return {
      animation: {
        duration: 0
      },
      elements: {
        point: 0
      },
      legend: {
          display: false,
          position: 'right',
          align: 'start',
          maxWidth: 150,
          fullSize: false
      },     
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
            {
              circular: true,
              gridLines: {
                  display: false,
                  tickBorderDashOffset: 4
              },
              ticks: {
                stepSize: 600
              }
            },
          ],
         xAxes: [
            {
              type: "time",
              time: {
                  unit: 'month',
                  parser:'DD/MM/YYYY',
                  displayFormats: {day: 'DD/MM/YYYY'},
                  stepSize: 1
              }
            },
          ],
      },
      plugins: {
        datalabels: {
          color: '#000',
          anchor: 'end',
          align: 'start',
          offset: '-18',
          display: false,
          font: {
                weight: 'bold'
              },
          
        }
      }
      }
    }
  },
  mounted () {
      
    Chart.plugins.register({
        beforeInit: function(chart) {
          if (chart.canvas.id == 'custom-line-gr') {
            Chart.controllers.LineWithLine = Chart.controllers.line.extend({
              draw: function(ease) {
                Chart.controllers.bubble.prototype.draw.call(this, ease);
                var ctx = this.chart.chart.ctx;
                
                ctx.restore();
              }   
            })
          }
        }
      });

      this.renderChart(this.chartData, this.options)


  }
}
</script>