<template>
  <div :class="{ premiumDisabled: !hasPremium }">
    <div class="d-flex justify-end mb-2 items-center pt-2">
      <BreadCrumbs :borderBottom="false" class="flex-1"></BreadCrumbs>
      <DatePicker class="calendar-cn" v-model="calendar.date" type="month" :clearable="calendar.clearable"
        not-before="2016-01-01" @input="changeCalendar" :disabled-date="getDisabledDate" />

    </div>
    <skeleton-card v-if="isLoading"></skeleton-card>
    <div v-else-if="!isLoading && !notData">

      <!--metrics facebook-->
      <div v-if="isDemoFacebook" class="boxMetricsBySite mt-4">
        <img :src="img.facebookText" />
        <hr class="borderLogo" />
        <div class="mt-6">
          <div class="d-flex mt-3">
            <v-row class="ma-0" style="gap: 20px">
              <v-col class="col-6 d-flex pa-0 boxGrupoAcciones" style="flex: 0 0 calc(30% - 10px)">
                <BoxActionInProfile class="boxAccion" v-for="(action, index) in dataMockedFacebook.slice(0, 1)"
                  color="#3C5A99" :data="action" :key="index"></BoxActionInProfile>
              </v-col>

              <v-col class="col-6" style="flex: 0 0 calc(70% - 10px)">&nbsp;</v-col>
            </v-row>
          </div>
          <div class="d-flex mt-3 facebookInteractions">
            <div class="d-flex flex-column pl-4">
              <img src="@/assets/img/local/metrics/facebook/like.png" class="mb-2" />
              Total de Reacciones
            </div>
            <div class="d-flex flex-column justify-center items-center"
              v-for="(action, index) in dataMockedFacebook.filter(e => e.inter)" :key="index">
              <img :src="action.ico" />
              <span class="name">{{ action.name }}</span>
              <span class="number">{{ action.number }}</span>
            </div>
          </div>
          <div class="d-flex boxGrupoAcciones mt-3">
            <BoxActionInProfile class="boxAccion"
              v-for="(action, index) in dataMockedFacebook.filter(e => !e.inter).slice(1, dataMockedFacebook.length)"
              color="#3C5A99" :data="action" :key="index"></BoxActionInProfile>
          </div>
        </div>
      </div>

      <!--metrics instagram-->
      <div v-if="isDemoFacebook" class="boxMetricsBySite mt-4">
        <img :src="img.instagramText" />
        <hr class="borderLogo" />
        <div class="mt-6">
          <div class="d-flex mt-3">
            <v-row class="ma-0" style="gap: 20px">
              <v-col class="col-6 d-flex pa-0 boxGrupoAcciones" style="flex: 0 0 calc(50% - 10px)">
                <BoxActionInProfile class="boxAccion" v-for="(action, index) in dataMockedInstagram.slice(0, 3)"
                  color="#B51C32" :data="action" :key="index"></BoxActionInProfile>
              </v-col>
              <v-col class="col-6" style="flex: 0 0 calc(50% - 10px)">&nbsp;</v-col>
            </v-row>
          </div>
          <div class="d-flex mt-3 instagramInteractions">

            <div class="d-flex flex-column justify-center items-center"
              v-for="(action, index) in dataMockedInstagram.filter(e => e.inter)" :key="index">
              <span class="name">{{ action.name }}</span>
              <span class="number">{{ action.number }}</span>
            </div>
          </div>
          <div class="d-flex boxGrupoAcciones mt-3">
            <BoxActionInProfile class="boxAccion"
              v-for="(action, index) in dataMockedInstagram.filter(e => !e.inter).slice(3, dataMockedInstagram.length)"
              color="#B51C32" :data="action" :key="index"></BoxActionInProfile>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="notData" class="boxMetricsBySite">
      <hr class="borderLogo" />
      <div class="d-flex justify-center align-center">
        <div class="my-8">
          <p class="text-center font-weight-bold">{{ $gettext('No se han encontrado datos') }}</p>
          <p class="d-flex justify-center align-center"><img src="@/assets/img/no_hay_datos.svg" /></p>
        </div>
      </div>


    </div>
  </div>
</template>
<script>
import SrvLocalMetrics from "@/services/localMetrics";
import SkeletonCard from "@/components/common/skeletonCard.vue";
import commonMixin from "@/mixins/common.js";
import localMixin from "@/mixins/local.js";
import brandMixin from '@/mixins/brand.js'
import DataKeywords from "@/components/local/metrics/keywords.json";
import DataMetricsMock from "@/components/local/metrics/DataMetricsMock.json";
import BoxActionInProfile from "@/components/local/metrics/BoxActionInProfile.vue";
import BreadCrumbs from "@/components/common/Breadcrumbs.vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es'
export default {
  components: { BreadCrumbs, SkeletonCard, BoxActionInProfile, DatePicker },
  mixins: [commonMixin, localMixin, brandMixin],
  data() {
    return {
      'calendar': {
        'clearable': false,
        'date': new Date(),
      },
      img: {
        googleText: require("@/assets/img/google-text.svg"),
        facebookText: require("@/assets/img/facebook-text.svg"),
        instagramText: require("@/assets/img/instagram-text.svg"),
      },
      data: null,
      dataMockedFacebook: [
        /*{ id: 'page_actions_post_reactions_like_total', name: "Nº de publicaciones", number: "2.396", description: "", ico: require("@/assets/img/local/metrics/facebook/npublicaciones.svg") },
        { id: 'page_actions_post_reactions_love_total', name: "Nº de seguidores", number: "2.228", description: "", ico: require("@/assets/img/local/metrics/facebook/nseguidores.svg") },*/
        { id: 'page_fan_adds', name: "Nº de Me gusta", number: "-", description: "", ico: require("@/assets/img/local/metrics/facebook/nlikes.svg") },
        { id: 'page_actions_post_reactions_like_total', name: "Me gusta", number: "-", description: "", ico: require("@/assets/img/local/metrics/facebook/megusta.png"), inter: true },
        { id: 'page_actions_post_reactions_love_total', name: "Me encanta", number: "-", description: "", ico: require("@/assets/img/local/metrics/facebook/meencanta.png"), inter: true },
        { id: 'page_actions_post_reactions_wow_total', name: "Me asombra", number: "-", description: "", ico: require("@/assets/img/local/metrics/facebook/measombra.png"), inter: true },
        { id: 'page_actions_post_reactions_haha_total', name: "Me divierte", number: "-", description: "", ico: require("@/assets/img/local/metrics/facebook/medivierte.png"), inter: true },
        { id: 'page_actions_post_reactions_sorry_total', name: "Me entristece", number: "-", description: "", ico: require("@/assets/img/local/metrics/facebook/meentristece.png"), inter: true },
        { id: 'page_actions_post_reactions_anger_total', name: "Me enoja", number: "-", description: "", ico: require("@/assets/img/local/metrics/facebook/meenoja.png"), inter: true },


        { id: 'page_views_total', name: "Interacciones", number: "-", description: "Suma de reacciones, comentarios, compartidos y clics.", ico: require("@/assets/img/local/metrics/facebook/interacciones.svg") },
        { id: 'page_impressions', name: "Impresiones", number: "-", description: "total de veces que se han visto las publicaciones", ico: require("@/assets/img/local/metrics/facebook/impresiones.svg") },
        { id: 'page_post_engagements', name: "Alcance", number: "-", description: "usuarios únicos que han llegado a las publicaciones", ico: require("@/assets/img/local/metrics/facebook/alcance.svg") },
        { id: 'page_views_total', name: "Visitas al perfil", number: "-", description: "Nº de visitas del perfil de la página", ico: require("@/assets/img/local/metrics/facebook/visitas.svg") },
        { id: 'page_consumptions', name: "Contenido", number: "-", description: "Nº de veces que las personas hicieron clic en algunos de tus contenidos", ico: require("@/assets/img/local/metrics/facebook/comentarios.svg") },
      ],
      dataMockedInstagram: [
        { id: 'impressions', name: "Nº de impresiones", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/npublicaciones.svg") },
        { id: 'reach', name: "Alcance", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/nseguidores.svg") },
        { id: 'likes', name: "Nº de Likes", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/likes.svg") },

        { id: 'impressions', name: "Nº Impresiones", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/visitasperfil.svg"), inter: true },
        { id: 'email_contacts', name: "Nº de clicks sobre email", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/visitasperfil.svg"), inter: true },
        { id: 'phone_call_clicks', name: "Nº de clicks sobre teléfono", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/visitasperfil.svg"), inter: true },
        { id: 'text_message_clicks', name: "Nº de clicks sobre mensaje", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/visitasperfil.svg"), inter: true },
        { id: 'get_directions_clicks', name: "Nº  de clicks sobre dirección", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/visitasperfil.svg"), inter: true },
        { id: 'website_clicks', name: "Nº clicks sobre website", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/visitasperfil.svg"), inter: true },
        { id: 'profile_views', name: "Nº visitas al perfil", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/visitasperfil.svg"), inter: true },

        { id: 'total_interactions', name: "Interacciones", number: "-", description: "total de veces que se han visto las publicaciones", ico: require("@/assets/img/local/metrics/instagram/impresiones.svg") },
        { id: 'reach', name: "Alcance", number: "-", description: "usuarios únicos que han llegado a las publicaciones", ico: require("@/assets/img/local/metrics/instagram/alcance.svg") },
        { id: 'comments', name: "Comentarios", number: "-", description: "Nº de Comentarios", ico: require("@/assets/img/local/metrics/instagram/comentarios.svg") },
        { id: 'replies', name: "Respuestas", number: "-", description: "Nº de Respuestas", ico: require("@/assets/img/local/metrics/instagram/comentarios.svg") },
        { id: 'saves', name: "Guardados", number: "-", description: "Nº de guardados", ico: require("@/assets/img/local/metrics/instagram/guardados.svg") },
        { id: 'shares', name: "Compartidos", number: "-", description: "Nº de compartidos", ico: require("@/assets/img/local/metrics/instagram/compartidos.svg") },
      ],
      dataSearchDesktop: null,
      dataMetrics: null,
      dataViewsProfile: null,
      dataChartViews: null,
      totalView: null,
      interactions_ranking: null,
      totalInteractions: null,
      notData: false,
      restaurantsMoreSearch: null,
      imagesKey: {
        BUSINESS_IMPRESSIONS_DESKTOP_MAPS: require("@/assets/img/local/metrics/google/search-maps-desktop.svg"),
        BUSINESS_IMPRESSIONS_DESKTOP_SEARCH: require("@/assets/img/local/metrics/google/search-desktop.svg"),
        BUSINESS_IMPRESSIONS_MOBILE_MAPS: require("@/assets/img/local/metrics/google/search-maps.svg"),
        BUSINESS_IMPRESSIONS_MOBILE_SEARCH: require("@/assets/img/local/metrics/google/search-movil.svg"),
      },
      namesKey: {
        BUSINESS_IMPRESSIONS_DESKTOP_MAPS: "Google Maps (ordenador)",
        BUSINESS_IMPRESSIONS_DESKTOP_SEARCH: "Búsqueda de Google  (ordenador)",
        BUSINESS_IMPRESSIONS_MOBILE_MAPS: "Google Maps  (móvil)",
        BUSINESS_IMPRESSIONS_MOBILE_SEARCH: "Búsqueda de Google  (móvil)",
      },
      colorKey: {
        BUSINESS_IMPRESSIONS_DESKTOP_MAPS: "#DC2B1F",
        BUSINESS_IMPRESSIONS_DESKTOP_SEARCH: "#1970E8",
        BUSINESS_IMPRESSIONS_MOBILE_MAPS: "#F19700",
        BUSINESS_IMPRESSIONS_MOBILE_SEARCH: "#188A42",
      },
    };
  },
  methods: {
    getDisabledDate(date) {
      return date > new Date().setHours(0, 0, 0, 0);
    },
    processData(data) {
      this.interactions_ranking = data?.interactions_ranking;
      this.totalView = data.main_metrics_data[data.main_metrics_data.length - 1].total_impressions;
      this.totalInteractions = data?.submetrics?.total;
      this.dataViewsProfile = data.main_metrics_data.filter((e) => Object.keys(this.namesKey).includes(e.metric));
      this.createDataChartViews(this.dataViewsProfile);
      this.dataMetrics = { timeSeries: data.submetrics.uniqueTimeSeries };
      this.dataActionsGoogleFilteredByBrand.forEach((e) => {
        if (data.submetrics.metrics.filter((x) => e.metric === x.metric_name).length > 0) {
          e.number = data.submetrics.metrics.filter((x) => e.metric === x.metric_name)[0].count;
        } else {
          e.hide = true;
        }
      });
    },
    getNumberByMetricByType(key, index) {
      let metric = this.dataViewsProfile.filter((e) => e.metric === key);
      let num = 0;
      if (metric.length > 0) {
        num = metric[0].summary[index].total;
      }
      return num;
    },
    formatCustom(val) {
      this.$formatNumber(val, 2, 2);
    },
    createDataChartViews(dataViews) {
      let data = {
        labels: [],
        datasets: [
          {
            label: "",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          },
        ],
      };
      Object.keys(dataViews).forEach((e) => {
        if (!["total", "uniqueTimeSeries"].includes(e)) {
          data.labels.push(dataViews[e].metric_es);
          data.datasets[0].data.push(dataViews[e].total_impressions);
          data.datasets[0].backgroundColor.push(this.colorKey[e] || this.colorKey[dataViews[e]?.metric]);
        }
      });
      this.dataChartViews = data;
    },
    async getData() {
      this.$store.commit("LOADING", true);
      if (this.hasPremium) {
        try {


          if (this.isDemoFacebook) {
            const resMeta = await SrvLocalMetrics.getLocalMetricsMeta(this.$moment(this.calendar.date).format("YYYY-MM-01"), this.$moment(this.calendar.date).format("YYYY-MM-30"))
            //const resMeta = await SrvLocalMetrics.getLocalMetricsMeta(this.$moment('2022-11-01').format("YYYY-MM-DD"), this.$moment('2022-11-30').format("YYYY-MM-DD"))
            if (resMeta.status === 200) {
              this.dataMockedFacebook.map(e => {
                if (resMeta.data.facebook.filter(f => f.name === e.id).length > 0) { e.number = resMeta.data.facebook.filter(f => f.name === e.id)[0].total }
              })
              this.dataMockedInstagram.map(e => {
                if (resMeta.data.instagram.filter(f => f.name === e.id).length > 0) { e.number = resMeta.data.instagram.filter(f => f.name === e.id)[0].total }
              })
              this.dataMockedInstagram.map(e => {
                if (Array.isArray(resMeta.data.instagram_interactions) && resMeta.data.instagram_interactions.filter(f => f.name === e.id).length > 0) { e.number = resMeta.data.instagram_interactions.filter(f => f.name === e.id)[0].total_value.value }
              })
            }
          }
        } catch (e) {
          this.notData = true
          this.$store.commit("LOADING", false);
        }

      } else {
        if (this.typeNode === "center") {
          this.dataSearchDesktop = DataKeywords;
        }
        this.processData(DataMetricsMock);
      }
      this.$store.commit("LOADING", false);
    },
    changeCalendar() {
      this.getData();
    },
  },
  computed: {
    dataActionsGoogleFilteredByBrand() {
      if (this.isAdverbia) {
        return this.dataActionsGoogle.filter(e => e.name !== 'Menu')
      }
      return this.dataActionsGoogle
    },
    viewsProfile() {
      let data = [];
      Object.keys(this.dataViewsProfile).forEach((e) => {
        if (!["total", "uniqueTimeSeries", "submetrics"].includes(e)) {
          data.push({ porcentaje: this.dataViewsProfile[e].porcentaje.substring(0, this.dataViewsProfile[e].porcentaje.length - 1), count: this.dataViewsProfile[e].total_impressions, name: this.namesKey[e] || this.namesKey[this.dataViewsProfile[e].metric], ico: this.imagesKey[e] || this.imagesKey[this.dataViewsProfile[e].metric], color: this.colorKey[e] || this.colorKey[this.dataViewsProfile[e].metric] });
        }
      });
      return data.sort((a, b) => b.count - a.count);
    },
  },
  created() {
    this.getData();
  },
  watch: {
    filterApply: function () {
      this.getData();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.boxMetricsBySite {
  border: 1px solid #cecece;
  background-color: #fff;
  border-radius: 4px;
  margin: auto 10px;
  padding: 25px;

  .facebookInteractions,
  .instagramInteractions {
    gap: 6%;
    background-color: #F4F6FC;
    text-align: center;
    padding-top: 28px;
    padding-bottom: 16px;

    img {
      width: 30px;
    }

    .name {
      font-size: 14px;
      color: #3C5A99;
      font-weight: 600;
      margin-top: 13px;
      margin-bottom: 17px;
    }

    .number {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .instagramInteractions {
    justify-content: center;
    gap: 1rem;

    .name {
      color: #b51c32;
      font-weight: 400;
    }
  }

  .borderLogo {
    border: 1px solid #cecece;
    margin-top: 10px;
  }

  .boxCharts {
    gap: 4%;

    .boxPersonas {
      width: 54%;
    }

    .boxInteracciones {
      width: 42%;
    }
  }

  .boxGrupoAcciones {
    gap: 20px;

    &.interactions {
      gap: 0;
    }

    .boxAccion {
      flex: 1 1 0px;
    }
  }

  .boxSearchs {
    gap: 20px;

    .search {
      flex-direction: column;
      width: 40%;

      .numberInResult {
        background-color: #f4f6fc;
        padding: 15px;
        flex: 1 1 0%;
      }
    }

    .searchRestaurant {
      width: 60%;
    }

    .search,
    .searchRestaurant {
      .infoForRestaurant {
        background-color: #f4f6fc;
        padding: 15px;

        .rowCenter {
          margin: 0;
          padding: 5px;
          border-bottom: 2px solid #ffffff;
        }
      }
    }
  }
}
</style>
  