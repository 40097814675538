<template lang="pug">
    div
        date-picker(class="calendar-cn" v-model="calendar.date" :lang="calendar.lang" :format="calendar.format" type="date" :first-day-of-week="calendar.firstDayOfWeek" :disabled-date="notBeforeToday" range :clearable="calendar.clearable" not-before="2016-01-01" @input="changeCalendar" confirm)
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es'

export default {
    props: ['range'],
    data(){
        return{
            'calendar': {
                'clearable': false,
                'date':null,
                lang: {
                    formatLocale: {
                        firstDayOfWeek: 0,
                    },
                    monthBeforeYear: false,
                },
                'format': 'DD/MM/YYYY', // 'MMM YYYY'
                'firstDayOfWeek': 0
            }
        }
    },
    components: { DatePicker },
    created(){
        if (this.range) {
            this.calendar.date = this.range
        } else {
            this.calendar.date = [this.$moment().add({'months': -1}).toDate(), this.$moment().toDate()]
        }        
    },
    methods:{
        notBeforeToday(date) {
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        changeCalendar (value) {
            this.$emit('rangeSelected', value)
           /* if (value.length === 2) {
                this.$store.commit(types.COMMON_DATE_AFTER, this.$moment(value[0]).format('YYYY-MM-DD'));
                this.$store.commit(types.COMMON_DATE_BEFORE, this.$moment(value[1]).format('YYYY-MM-DD'));
            }*/
        },
    }  
}
</script>
