<script>
import { Doughnut, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: "valid_name",
  extends: Doughnut,
  type: 'doughnut',
  mixins: [reactiveProp],
  props:['chartData'],
  data: () => ({
    chartDataCustom: null,
  }),
  created () {
    this.chartDataCustom = this.chartData
  },
  watch: {
    chartDataCustom () {
      this.renderChart(this.chartData, this.options)
    }
  },
  computed: {
    options () {
      return {
        legend: {
            display: false,
        },     
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 50,
        plugins: {
        datalabels: {
          display: false
        }
      }
      }      
    }
  },
  mounted () {
      this.renderChart(this.chartData, this.options)
  }
}
</script>