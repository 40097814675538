<template>
    <div class="boxActionInProfile">
        <div class="d-flex" :class="{'flex-column justify-center items-center': data.inter}">
            <span class=" text-14" :class="{'flex-1': !data.inter}" :style="'color:'+color">{{ data.inter ? '"' : ''}}{{ data.name }}{{ data.inter ? '"' : ''}}</span>
            <img :src="data.ico" />
        </div>
        <strong>{{ data.number | formatNumber }}</strong>
        <p class="text-12">{{ data.description }}</p>
    </div>
</template>
<script>
export default {
    props: {
        color: {
            default: '#608DF2'
        },
        data: {
            default: null
        }
    }
}
</script>
<style scoped>
.boxActionInProfile{
    background-color: #F4F6FC;
    padding: 24px 12px 0 12px;
}
</style>